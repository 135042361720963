import React from 'react';
import {Link} from "react-router-dom";

import "./styles.css";

const Hamburger = props => {
    return (
        <div className="menu-wrap">
            <input type="checkbox" className="toggler" />
            <div className="hamburger">
                <div>
                    
                </div>
            </div>
            <div className="menu">
                <div>
                    <div>
                        <ul>
                        <li><Link to="/home">HOME</Link></li>
                        <li><Link to="/project">Projects</Link></li>
                        {/* <li><Link to="/blog">Blog</Link></li> */}
                        <li><Link to="/about">ABOUT</Link></li>
                        <li><Link to="/contact">CONTACT</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hamburger;